export default {
  baseURL: 'https://api.carex.bj',
  // baseURL: 'http://45.79.211.172:3001',
  baseURL2: 'https://documinio.41devs.com/api/v1/', // 'https://blog41.kgeek.me/api/v1/',
  login: '/api/v1/admin/login',
  changePassword: '/api/v1/auth/changeActualPassword',
  createAdmin: '/api/v1/admin/createAdmin',
  getAdmin: '/api/v1/admin/allAdmin-paginate', // '/api/v1/admin',
  createNewArticle: '/api/v1/blog',
  getAllArticle: '/api/v1/blog',
  createFile: '/api/v1/file',
  getFileById: '/api/v1/file/',
  uploadFile: 'uploader/upload', // 'utilities/upload-file',
  getAllDemand: '/api/v1/admin/allDemande-paginate', // '/api/v1/admin/allDemande',
  validationDemand: '/api/v1/admin/doctorValidation',
  getAllCategories: '/api/v1/categorie',
  createQuiz: '/api/v1/quiz',
  getQuiz: '/api/v1/quiz',
  getCategoryByType: '/api/v1/categorie/findByCategorie',
  createSSR: '/api/v1/ssr/create-ssr',
  getAllSSR: '/api/v1/ssr',
  createCategory: '/api/v1/categorie',
  createQuestion: '/api/v1/question',
  createProposition: '/api/v1/proposition',
  getQuestionByQuiz: '/api/v1/question/byQuiz',
  getPropositionByQuestion: '/api/v1/proposition/byQuestion',
  getSSRDoctorByPoint: '/api/v1/ssr/agent',
  getAllUser: '/api/v1/admin/allUser-paginate', // '/api/v1/admin/allUser',
  userStat: '/api/v1/stats/user',
  getAllStat: '/api/v1/stats/global',
  deleteAdmin: '/api/v1/admin/deleteAdmin',
  getAllForum: '/api/v1/admin/allForum',
  deleteForum: '/api/v1/admin/deleteForum',
  getArticleByCategory: '/api/v1/blog/findByCategorie',
  getQuizByCategory: '/api/v1/quiz/findByCategorie',
  deleteArticle: '/api/v1/blog/',
  forgetPassword: '/api/v1/admin/forgot-password',
  resetPassword: '/api/v1/admin/initialise-password',
  deleteQuestion: '/api/v1/question/',
  updateQuestion: '/api/v1/question',
  updateProposition: '/api/v1/proposition',
  updateQuiz: '/api/v1/quiz',
  deleteSSR: '/api/v1/ssr/',
  updateArticle: '/api/v1/blog',
  deleteQuiz: '/api/v1/quiz/',
  deleteProposition: '/api/v1/proposition/',
  deleteCategory: '/api/v1/categorie/',
  updateCategory: '/api/v1/categorie',
  updateAdmin: '/api/v1/admin/update-profile',
  findUser: '/api/v1/admin/findUser'
}
